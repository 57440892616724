import {Controller} from "stimulus";
import Sortable from 'sortablejs'

export default class extends Controller {
    connect() {
        Sortable.create(this.element, {
            onSort: this.onSort.bind(this),
            group: 'songBuilder',
        });
        this.input = this.element.previousSibling;
    }

    onSort() {
        const ids = [];
        const loops = this.element.querySelectorAll('.loop');
        loops.forEach((elem, index) => {
          ids.push(elem.dataset.loopId);
        });
        this.input.value = ids.join(',');
        if (loops.length) {
          const advice = this.element.querySelector('.advice');
          advice && advice.remove();
        } else {
          const advice = document.createElement('div');
          advice.classList.add('list-group-item');
          advice.classList.add('advice');
          advice.textContent = 'Drag and drop loops here';
          this.element.appendChild(advice);
        }
        window.clearTimeout(window.songBuilderTimeout);
        window.songBuilderTimeout = window.setTimeout(() => {
          Rails.fire(document.getElementById('song-builder'), 'submit');
        }, 1000)
    }
}
