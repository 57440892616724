import { Controller } from 'stimulus'
import React from 'react'
import ReactDOM from 'react-dom'

export default class extends Controller {
    connect() {
        const Component = window.ReactComponents[this.element.dataset.component]
        let props = this.element.dataset.props || {}
        if (typeof props === 'string') {
            props = JSON.parse(props)
        }

        const component = React.createElement(Component, props);

        ReactDOM.render(component, this.element)

        document.addEventListener('turbolinks:before-cache', this.unmountComponent)
    }

    disconnect() {
        document.removeEventListener('turbolinks:before-cache', this.unmountComponent)
    }

    unmountComponent() {
        ReactDOM.unmountComponentAtNode(this.element)
    }
}
