import React from 'react';

class AudioPlayer extends React.PureComponent {
  ref = React.createRef()
  state = { playing: false }
  render() {
    const { playing } = this.state;
    const { id } = this.props;
    return (
      <span>
        <audio ref={this.ref} loop src={`/loops/${id}.mp3`} />
        <button className='btn btn-sm btn-link' onClick={() => playing ? this.pause() : this.play()}>
          <i className={`fa fa-${playing ? 'pause' : 'play'}`} />
        </button>
      </span>
    )
  }

  play() {
    this.setState({ playing: true })
    this.ref.current.play()
  }

  pause() {
    this.setState({ playing: false })
    this.ref.current.pause()
  }
}

export default AudioPlayer;
