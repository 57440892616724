import {Controller} from "stimulus";
import Sortable from 'sortablejs'

export default class extends Controller {
    connect() {
        Sortable.create(this.element, {
            onEnd: this.onEnd.bind(this),
            handle: this.element.dataset.handle || '.handle',
            group: this.element.dataset.group || 'shared'
        })
        this.element.querySelectorAll('.list-group-item').forEach((item) => {
            const hiddenInput = item.nextSibling;
            if (hiddenInput && hiddenInput.tagName === 'input' && hiddenInput.type === 'hidden') {
              item.parentNode.removeChild(hiddenInput)
              item.appendChild(hiddenInput)
            }
        })
    }

    onEnd() {
        const selector = this.element.dataset.selector || '.list-group-item'
        this.element.querySelectorAll(selector).forEach((elem, index) => {
            const input = elem.querySelector('.position')
            if (input)
                input.setAttribute('value', index + 1)
        })
    }
}
