// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '@coreui/coreui/dist/css/coreui.min.css'
import '../stylesheets/application.scss'
import "@coreui/coreui/dist/js/coreui"

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "controllers"
import "components"

window.Rails = Rails
Rails.start()
// Turbolinks.start()
// ActiveStorage.start()

function initUI() {
    $('.c-sidebar').sidebar()
    $('[rel=tooltip]').tooltip()
}

$(document).on('turbolinks:load', initUI)
// $(document).ready(initUI)
