import {Controller} from "stimulus";

export default class extends Controller {
    connect() {
        this.input = this.element.parentNode.querySelector('input, textarea')
        this.show()
        this.input.addEventListener('keyup', this.show.bind(this))
    }

    show() {
        const { min, max, zen } = this.element.dataset
        const { value } = this.input
        const boilerplate = ` (target is ${zen} words)`
        if (!value || value === '') {
            this.element.className = 'text-default'
            this.element.textContent = `0 words${boilerplate}`
        } else {
            const numWords = value.replace(/\s+/g, ' ').split(' ').length
            let className = "text-"
            if (numWords < min || numWords > max) className += 'danger';
            else if (numWords > zen) className += 'warning'
            else className += 'default'
            this.element.className = `${className}`
            this.element.textContent =  `${numWords} word${numWords > 1 ? 's' : ''}${boilerplate}`
        }
    }
}
