import {Controller} from "stimulus";

export default class extends Controller {
    connect() {
        const div = this.element.querySelector('div')
        const hiddenInput = div.querySelector('input[type=hidden]')
        if (hiddenInput) {
            div.removeChild(hiddenInput)
        }
        div.classList.add('btn-group')
        div.classList.add('btn-group-toggle')
        const colors = JSON.parse(this.element.dataset.colors)
        const labels = div.querySelectorAll('label.form-check-label')
        labels.forEach((label, index) => {
            const input = label.querySelector('input[type=radio]')
            label.classList.remove('form-check-label')
            label.classList.add('btn')
            label.classList.add('btn-secondary')
            if (input.checked)
                label.classList.add('active')
            label.style.backgroundColor = `#${colors[index]}`
            label.addEventListener('click', () => {
                labels.forEach(l => l.classList.remove('active'))
                label.classList.add('active')

            })
        })
    }
}
