import {Controller} from "stimulus";
import Sortable from 'sortablejs'

export default class extends Controller {
    connect() {
        Sortable.create(this.element, {
            onSort: this.onSort.bind(this),
            group: 'songBuilder',
            sort: false
        })
    }

    onSort() {
      const loops = this.element.querySelectorAll('.loop');
      if (loops.length) {
        const advice = this.element.querySelector('.advice');
        advice && advice.remove();
      } else {
        const advice = document.createElement('div');
        advice.classList.add('list-group-item');
        advice.classList.add('advice');
        advice.textContent = 'Drag and drop loops here';
        this.element.appendChild(advice);
      }
    }
}
