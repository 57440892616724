import {Controller} from "stimulus";

export default class extends Controller {
    connect() {
        this.input = this.element.previousSibling
        if (this.input.tagName !== 'INPUT')
            this.input = this.input.previousSibling
        this.countCharacters()
        this.input.addEventListener('keyup', this.countCharacters.bind(this))
    }

    countCharacters() {
        const { value } = this.input
        if (value === '') {
            this.element.innerHTML = '0 characters'
            this.element.className = 'text-default'
        }

        const { min, max, zen } = this.element.dataset

        const numChars = value.length
        let className = "text-"
        if (numChars < min || numChars > max) className += 'danger';
        else if (numChars > zen) className += 'warning'
        else className += 'default'

        this.element.innerHTML = numChars === 1 ? '1 character' : `${numChars} characters`
        this.element.className = className
    }
}
