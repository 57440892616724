import {Controller} from "stimulus";

export default class extends Controller {
    connect() {
      $('input, select').on('change', () => {
          if (this.element.dataset.remote === 'true') {
              Rails.fire(this.element, 'submit');
          } else {
              this.element.submit();
          }
      })
    }
}
